import { HashLink as Link } from 'react-router-hash-link';

export default function AfterSubscribe() {
    return (
        <div className="mt-5">
            <div className="container-fluid pt-3 pb-3 pt-lg-5 pb-lg-5">
                <div className="mt-5 row justify-content-center">
                    <div className="col-12 col-lg-8 center">
                        Köszönjük, hogy feliratkoztál hírlevelünkre!
                    </div>
                </div>
                <div className="mt-5 row justify-content-center">
                    <div className="col-12 col-lg-6 center">
                        <Link to="/">
                            Tovább a kezdőlapra
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}