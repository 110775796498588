import { useEffect } from 'react';
import { Helmet } from 'react-helmet'

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Adatkezelési tájékoztató</title>
                <meta name="title" content="Adatkezelési tájékoztató" />
            </Helmet>
            <div className="">
                <div className="container-fluid mt-8">
                    <div className="pageHeader" style={{ minHeight: "auto" }}>
                        <h2 className="title1">Adatkezelési tájékoztató</h2>
                    </div>
                </div>

                <div className="container-fluid pt-lg-10 impress">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            <h3>I. A szabályzat célja</h3>
                            <p>Jelen szabályzat célja, hogy meghatározza a Nexodot Informatikai és Szolgáltató Kft. (1162 Budapest, János utca 26.) a továbbiakban: Szolgáltató által alkalmazott adatvédelmi és adatkezelési elveket, és biztosítsa az adatvédelem és az adatbiztonság követelményeinek érvényesülését. Az adatok megadása önkéntes, nem kötelező személyes adatok megadása, de azok hiányában nem lehetséges a webshopban elérhető termékek megvásárlása.</p>

                            <h3 className='mt-2'>II. A szabályzat hatálya</h3>
                            <p>A szabályzat kiterjed az Adatkezelő által kezelt weboldalon leadott megrendelések teljesítésére, valamint hírlevél küldésre.</p>
                            <p>Szolgáltató a nexodot.hu weboldal (a továbbiakban: Weboldal) működtetése során a Weboldalra látogatók (a továbbiakban együttesen Érintett) adatait kezeli.</p>
                            <p>A szabályzat hatálya kiterjed mindazon személyekre, akik a Szolgáltató által végzett adatkezelési, vagy adatfeldolgozási műveletekben részt vesznek, vagy a kezelt, és feldolgozott adathoz hozzáférnek.</p>
                            <p>A szabályzat 2023. 02. 10.-én lép hatályba.</p>

                            <h3 className='mt-2'>III. A szolgáltató, mint adatkezelő</h3>
                            <p>
                                Cégnév: Nexodot Informatikai és Szolgáltató Kft.<br></br>
                                Székhely: 1162 Budapest, János utca 26.<br></br>
                                Cégjegyzékszám: 01-09-674216<br></br>
                                Adószám: 12404140-2-42<br></br>
                            </p>

                            <h3 className='mt-2'>IV. Az adatkezelés jogalapja</h3>
                            <p>4.1. Az adatkezelésre a nexodot.hu internetes oldalon (a továbbiakban: Weboldal) található internetes tartalmak az Érintett önkéntes, megfelelő tájékoztatáson alapuló nyilatkozata alapján kerül sor, amely nyilatkozat tartalmazza az Érintett kifejezett hozzájárulását ahhoz, hogy a Weboldal használata során közölt személyes adataik felhasználásra kerüljenek. Az adatkezelés jogalapja az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. tv. (Infotv.) 5.§ (1) bek. a) pontja szerint az érintett önkéntes hozzájárulása alapján, valamint az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény alapján kerül sor. A hozzájárulást az Érintett az egyes adatkezelések tekintetében a Weboldal használatával, a regisztrációval, illetve a kérdéses adatok önkéntes megadásával adja meg.</p>
                            <p>4.2. Az adatkezelés célja a Weboldalon elérhető szolgáltatások nyújtásának biztosítása. A Szolgáltató az Érintett által rendelkezésre bocsátott adatokat célhoz kötötten, kizárólag a megrendelés teljesítése, házhoz szállítása, számlázás lehetővé tétele, kapcsolattartás, illetve amennyiben az Érintett feliratkozott hírlevélre, akkor hírlevél küldés és az esetlegesen létrejövő szerződés feltételeinek későbbi bizonyítása érdekében gyűjti. Az automatikusan rögzítésre kerülő adatok célja statisztikakészítés, az informatikai rendszer technikai fejlesztése, az Érintett jogainak védelme. A Szolgáltató a megadott személyes adatokat az e pontokban, valamint a 6. pontban részletesen írt céloktól eltérő célokra nem használja, illetve használhatja fel. Személyes adatok harmadik személynek vagy hatóságok számára történő kiadása - hacsak törvény ettől eltérően nem rendelkezik kötelező erővel - az Érintett előzetes, kifejezett hozzájárulása esetén lehetséges kizárólag.</p>
                            <p>4.3. Szolgáltató a neki megadott személyes adatokat nem ellenőrzi. A megadott adatok megfelelősségéért kizárólag az azt megadó személy felel. Bármely Érintett e-mail címének megadásakor egyben felelősséget vállal azért, hogy a megadott e-mail címről kizárólag ő vesz igénybe szolgáltatást. E felelősségvállalásra tekintettel egy megadott e-mail címen történt belépésekkel összefüggő mindennemű felelősség kizárólag azt az Érintett terheli, aki az e-mail címet regisztrálta.</p>


                            <h3 className='mt-2'>V. Adatkezelés célja</h3>

                            <h4 class="mt-2">5.1. Ügyfélszolgálati adatkezelés:</h4><br></br>
                            <p>Kezelt adatok:<br></br>
                                teljes név, cégnév, e-mail cím, telefonszám<br></br><br></br>

                                Adatkezelési cél:<br></br>
                                Ügyfelek megkülönböztetése, ügyfélszolgálati tevékenység ellátása, kapcsolattartás. Kapcsolat-felvételre érkező ügy kivizsgálása és pontos dokumentálása, vagy a telefonos ügyfélszolgálattal folytatott telefonbeszélgetés pontos dokumentálása annak érdekében, hogy az Adatkezelő rendelkezésére álljanak azok a kérések és észrevételek, amelyek az Adatkezelő tevékenységével kapcsolatosak. Az emailen történő kommunikáció archiválásra kerül, így bármely utólagos kérdés vagy vita esetén az információk eredeti formájukban rendelkezésre állnak és szükség esetén az üggyel összefüggésben az Adatkezelő kapcsolatba tud lépni a felhasználóval.<br></br><br></br>

                                Adatkezelés jogalapja:<br></br>
                                GDPR 6.§. (1) c. - Jogi kötelezettség teljesítése, Fgytv. 17/A.§.,<br></br>
                                Az ügyfélszolgálati tevékenységek során kezelt adatok kezelésének jogalapja az érintett hozzájárulása az, Info. tv. 5.§ (1) a) pontja, a GDPR 6. cikk (1) bekezdés a) pontja, és szerződés teljesítése a GDPR 6. cikk (1) bekezdés b) pontja alapján, jogi kötelezettség teljesítése a GDPR 6. cikk (1) bekezdés c) és a Fgy. tv. 17/A.§ alapján.<br></br><br></br>

                                Az adatkezelés időtartama:<br></br>
                                az érintett személy hozzájárulásának visszavonásáig, ennek hiányában az Adatkezelő az ügyfélszolgálathoz e-mailen beérkezett kérés Adatkezelőhöz történő megérkezésének napját, vagy a telefonbeszélgetést követően, a Ptk. 6:22. § alapján 5 év múlva törli. Ha az adatokat az Adatkezelő a Számv. tv. alapján köteles megőrizni, akkor az adatokat az Adatkezelő az érintett hozzájárulásától függetlenül csak az ügy Adatkezelőhöz történő megérkezésének napját, vagy a telefonbeszélgetést követő 8 év múlva törli. A panaszról felvett jegyzőkönyvek és az írásbeli panaszokra adott válaszok másolati példányai tekintetében az Fgytv. 17/A. § (7) bekezdése alapján 5 évig kell őrizni. A vásárlók könyvébe tett bejegyzések másodpéldányai tekintetében 2 év a megőrzési idő.</p>


                            <h4 className='mt-2'>5.2. Hírlevél küldés</h4><br></br>
                            <p>Kezelt adatok:<br></br>
                                E-mail cím<br></br><br></br>

                                Adatkezelési cél:<br></br>
                                Érintettek részére gazdasági reklámot is tartalmazó, üzletszerzési célból, elektronikus úton megküldött levél (e-mail)<br></br><br></br>

                                Adatkezelés jogalapja:<br></br>
                                GDPR 6. cikk (1) a. pont- önkéntes hozzájárulás<br></br><br></br>

                                Az adatkezelés időtartama:<br></br>
                                Érintett hozzájárulásának (leiratkozás) visszavonásáig</p>


                            <h3 className='mt-2'>VI. Adatkezelésre/adattárolásra vonatkozó rendelkezések</h3>
                            <p>6.1. Az adatkezelések megvalósítása előtt - a jogszabályi előírások figyelembevételével – érthető és egyértelmű tájékoztatást kell közzé tenni az adatfelvétel módjáról, céljáról és – az adatszolgáltatás önkéntessége esetén - az önkéntes jellegről. Amennyiben Szolgáltató az Érintett személyes adatait az Érintett hozzájárulása alapján kezeli, úgy az adatkezelésre az Érintettek megfelelő tájékoztatáson alapuló önkéntes nyilatkozata alapján kerül sor, amely nyilatkozat tartalmazza az Érintettek kifejezett hozzájárulását ahhoz, hogy az általuk közölt személyes adataik felhasználásra kerüljenek.</p>
                            <p>6.2. Személyes adatok harmadik személy vagy hatóságok számára történő kiadása - hacsak törvény ettől eltérően kötelező erővel nem rendelkezik– kizárólag hatósági határozat alapján, vagy az érintett előzetes, kifejezett hozzájárulása esetén lehetséges. Elektronikus úton történő adatmegadás esetén az elektronikus felületen történő egyértelmű elfogadó mező jelölése jelenti a tájékoztatás megértését és elfogadását.</p>
                            <p>6.3. Szolgáltató a neki megadott személyes adatokat nem ellenőrzi. A megadott adatok megfelelőségéért kizárólag az azt megadó személy felel.</p>
                            <p>6.4. A szerződés megkötéséhez és teljesítéséhez szükséges személyes adatok hiányában a szerződés megkötésére nem kerülhet sor. Szolgáltató az Érintettek kényelmének biztosítása érdekében termékei többségét futárszolgálat igénybevételével szállítja ki az Érintett által megjelölt szállítási címre, melyről az Érintett már a szerződés megkötésekor a szerződésben tájékoztatást kap. Szolgáltató a kiszállítást végző partnervállalat számára átadja a szállítandó terméket, a címzett nevét és postai címét, valamint a megadott telefonszámát. Ennek során kiköti, hogy a csomagszállító partner ezeket az adatokat a szállítás kivételével nem használhatja fel más célokra.</p>
                            <p>6.5. Szolgáltató minden szükséges lépést megtesz annak érdekében, hogy biztosítsa az adatok biztonságát. A Szolgáltató által használt informatikai infrastruktúrán belül az adatok megőrzésével és védelmével kapcsolatos feladatokat a Szolgáltató informatikai biztonsági politikájában foglalt elvek, eljárások szabályozzák. A személyes adatokhoz csak az illetékes munkaköröket betöltő személyek férhetnek hozzá - hozzáférési kontrollok alkalmazása mellett.</p>
                            <p>6.6. Az adatokat elsődlegesen Szolgáltató, illetve Szolgáltató belső munkatársai jogosultak megismerni, azonban azokat nem teszik közzé, harmadik személyek részére nem adják át. Amennyiben az arra feljogosított hatóságok a jogszabályokban előírt módon (pl. bűncselekmény gyanújával, hivatalos adat lefoglalási határozatban) kérik fel személyes adatok átadására Szolgáltatót, úgy - törvényi kötelezettségének eleget téve - átadja a kért és rendelkezésre álló információkat. Személyes adatok harmadik személy vagy hatóságok számára történő kiadása - hacsak törvény ettől eltérően kötelező erővel nem rendelkezik– kizárólag hatósági határozat alapján, vagy az érintett előzetes, kifejezett hozzájárulása esetén lehetséges.</p>


                            <h3 className='mt-2'>VII. Cookie</h3>
                            <p>A Weboldalon tett látogatások során egy vagy több cookie-t – azaz egy-egy karaktersorozatot tartalmazó kis fájlt – küld a Szolgáltató az Érintett számítógépére, amely(ek) révén annak böngészője egyedileg azonosítható lesz. Ezen cookie-k a Google által biztosítottak, felhasználásuk a Google Adwords rendszerén keresztül történik. Ezeket a cookie-kat csak egyes aloldalak látogatása esetén küldjük el az Érintett számítógépére, tehát ezekben csak az adott aloldal meglátogatásának tényét és idejét tároljuk, semmilyen más információt nem.<br></br>
                                Az így elküldött cookie-k felhasználása a következő: A külső szolgáltatók, közöttük a Google, ezen cookie-k segítségével tárolják, ha az Érintett korábban már látogatást tett a hirdető webhelyén, és ez alapján hirdetéseket jelenítenek meg az Érintett részére külső szolgáltatók - közöttük a Google - partnereinek internetes webhelyein. Az Érintett a Google hirdetések kikapcsolására szolgáló oldalon tilthatják le a Google cookie-jait.</p>

                            <h3 className='mt-2'>VIII. Adattovábbítás</h3>
                            <p>Szolgáltató az egyes adatkezelések során a személyes adatot harmadik országba nem továbbítja. Személyes adatok harmadik országba történő továbbítására akkor kerülhet sor, ha a Bizottság megállapította, hogy a harmadik ország, a harmadik ország valamely területe, vagy egy vagy több meghatározott ágazata megfelelő védelmi szintet biztosít. Az adattovábbítás a Bizottság megfelelőségi határozata alapján történik.</p>

                            <h3 className='mt-2'>IX. Adatfeldolgozás</h3>
                            <p>9.1. Szolgáltató bizonyos esetekben adatokat ad át harmadik személyek részére, így szerződés teljesítésében közreműködő alvállalkozó vagy alvállalkozók egy meghatározott csoportja részére, bírósági-, hatósági megkeresés vagy más törvényes adatszolgáltatás teljesítése érdekében.</p>
                            <p>9.2. Az Adatfeldolgozó és bármely, az Adatkezelő vagy az Adatfeldolgozó irányítása alatt eljáró, a személyes adatokhoz hozzáféréssel rendelkező személy az adatokat kizárólag a Szolgáltató utasításának megfelelően kezelheti, kivéve, ha az ettől való eltérésre őt uniós vagy tagállami jog kötelezi. Az Adatfeldolgozó önálló döntést nem hoz, kizárólag az Adatkezelővel kötött szerződés és a kapott utasítások szerint jogosult eljárni.  Az Adatkezelő ellenőrzi az Adatfeldolgozó munkáját. Az Adatfeldolgozó további adatfeldolgozó igénybevételére csak az Adatkezelő előzetes írásbeli hozzájárulásával jogosult.</p><br></br>
                            <p>Adatfeldolgozó továbbá:<br></br>

                                - MailChimp (Atlanta, Georgia, Egyesült Államok): marketing platform üzemeltetése, személyre szabott ajánlatok küldése<br></br>
                                - Google LLC (USA- Google Data Protection Office,Mountain View, California 94043) hirdetés, analitikai és mérési szolgáltatás, reklám megjelenítése<br></br>
                                - Facebook Inc. (USA, Palo Alto, Menlo Park California) hirdetés, analitikai és mérési szolgáltatás, reklám megjelenítése<br></br>
                                - Microsoft Azure (Redmond, Washington, Egyesült Államok, szerver: North-Europe) weboldal tárhely biztosítása, adatmentés<br></br>
                                - A Google LLC és tagvállalatai és a Facebook, Inc. szerepel az Európai Bizottság GDPR 45. cikk szerinti megfelelőségi határozata és a 2016/1260 bizottsági végrehajtási határozatában, valamint az ezek alapján felállított USA - EU Privacy Shield List-en, azaz az ide történő adattovábbítás nem minősül Európai Unión kívüli, harmadik országba történő adattovábbításnak és ahhoz nem szükséges az érintettek külön engedélye, valamint az oda történő adattovábbítás a GDPR 45. cikke alapján megengedett. Ezen vállalkozások vállalták a GDPR-nak való megfelelést.</p>


                            <h3 className="mt-2">X. Az Érintettek jogai</h3>

                            <p>Az Érintett bármikor jogosult tájékoztatást kérni a Szolgáltató által kezelt, rá vonatkozó személyes adatokról, továbbá bármikor módosíthatja azokat<br></br>
                                Az Érintett jogosult arra, hogy Szolgáltatótól visszajelzést kapjon arra vonatkozóan, hogy személyes adatainak kezelése folyamatban van-e, és ha ilyen adatkezelés folyamatban van, jogosult arra, hogy a személyes adatokhoz és a következő információkhoz hozzáférést kapjon:</p><br></br>

                            a) az adatkezelés céljai;<br></br>
                            b) az érintett személyes adatok kategóriái; adatfeldolgozó nevéről, címéről és az adatkezeléssel összefüggő tevékenységéről, az adatvédelmi incidens körülményeiről, hatásairól és az elhárítására megtett intézkedésekről, továbbá - az Érintett személyes adatainak továbbítása esetén - az adattovábbítás jogalapjáról és címzettjéről<br></br>
                            c) azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a személyes adatokat közölték vagy közölni fogják, ideértve különösen a harmadik országbeli címzetteket, illetve a nemzetközi szervezeteket;<br></br>
                            d) adott esetben a személyes adatok tárolásának tervezett időtartama, vagy ha ez nem lehetséges, ezen időtartam meghatározásának szempontjai;<br></br>
                            e) az érintett azon joga, hogy kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatok helyesbítését, törlését vagy kezelésének korlátozását, és tiltakozhat az ilyen személyes adatok kezelése ellen.<br></br><br></br>

                            Ha személyes adatoknak harmadik országba vagy nemzetközi szervezet részére történő továbbítására kerül sor, az érintett jogosult arra, hogy tájékoztatást kapjon a továbbításra vonatkozóan megfelelő garanciákról.


                            <h4 className='mt-2'>10.1. Helyesbítés, törlés</h4><br></br>
                            <p>Az Érintett jogosult arra, hogy kérésére Szolgáltató  indokolatlan késedelem nélkül helyesbítse a rá vonatkozó pontatlan személyes adatokat. Figyelembe véve az adatkezelés célját, az érintett jogosult arra, hogy kérje a hiányos személyes adatok – egyebek mellett kiegészítő nyilatkozat útján történő – kiegészítését.<br></br><br></br>

                                Szolgáltató tevékenységét, belső ügymenetét és eljárásrendjét tekintve az adatokat a kérelem beérkezése után a lehető legrövidebb időn belül helyesbíti.



                                Az Érintett jogosult arra, hogy kérésére az adatkezelő indokolatlan késedelem nélkül törölje a rá vonatkozó személyes adatokat, az Adatkezelő pedig köteles arra, hogy az érintettre vonatkozó személyes adatokat indokolatlan késedelem nélkül törölje, ha az alábbi indokok valamelyike fennáll:<br></br><br></br>

                                a) a személyes adatokra már nincs szükség abból a célból, amelyből azokat gyűjtötték vagy más módon kezelték;<br></br>
                                b) az érintett visszavonja az adatkezelés alapját képező hozzájárulását, és az adatkezelésnek nincs más jogalapja;<br></br>
                                c) az érintett tiltakozik az adatkezelés ellen, és nincs elsőbbséget élvező jogszerű ok az adatkezelésre, vagy az érintett a közvetlen üzletszerzés érdekében történt adatkezelés ellen tiltakozik;<br></br>
                                d) a személyes adatokat jogellenesen kezelték;<br></br>
                                e) a személyes adatokat az adatkezelőre alkalmazandó uniós vagy tagállami jogban előírt jogi kötelezettség teljesítéséhez törölni kell;<br></br></p>

                            <h4 className='mt-2'>10.2. Az adatkezelés korlátozásához való jog</h4><br></br>
                            <p>Az Érintett jogosult arra, hogy kérésére Szolgáltató korlátozza az adatkezelést, ha az alábbiak valamelyike teljesül:<br></br><br></br>

                                a) az érintett vitatja a személyes adatok pontosságát, ez esetben a korlátozás arra az időtartamra vonatkozik, amely lehetővé teszi, hogy Szolgáltató ellenőrizze a személyes adatok pontosságát;<br></br>
                                b) az adatkezelés jogellenes, és az érintett ellenzi az adatok törlését, és ehelyett kéri azok felhasználásának korlátozását;<br></br>
                                c) az adatkezelőnek már nincs szüksége a személyes adatokra adatkezelés céljából, de az érintett igényli azokat jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez; vagy<br></br>
                                d) az érintett tiltakozott az adatkezelés ellen; ez esetben a korlátozás arra az időtartamra vonatkozik, amíg megállapításra nem kerül, hogy az adatkezelő jogos indokai elsőbbséget élveznek-e az érintett jogos indokaival szemben.<br></br><br></br>

                                Ha az adatkezelés az előzőek alapján korlátozás alá esik, az ilyen személyes adatokat a tárolás kivételével csak az érintett hozzájárulásával, vagy jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez, vagy más természetes vagy jogi személy jogainak védelme érdekében, vagy az Unió, illetve valamely tagállam fontos közérdekéből lehet kezelni. Az adatkezelő az érintettet, akinek a kérésére korlátozták az adatkezelést, az adatkezelés korlátozásának feloldásáról előzetesen tájékoztatja.</p>


                            <h3 className='mt-2'>XI. Jogorvoslat</h3>
                            <p>Az Érintettnek lehetősége van arra, hogy Szolgáltató adatkezelésével összefüggésben felmerült észrevételeit, panaszait, kifogásait közvetlenül Szolgáltató részére küldje meg jelen Szabályzatban megjelölt elérhetőségein, illetve kérje az általa igényelt jogorvoslatot. Az Érintett az adatkezeléssel, az adatkezeléssel összefüggő jogokkal kapcsolatos kérdéseivel, panaszaival, bejelentéseivel a Szolgáltató Ügyfélszolgálatához fordulhat. Az Ügyfélszolgálat gondoskodik arról, hogy a kérdések, panaszok, bejelentések a megfelelő szervezeti egységhez eljussanak, és azokra az érintett határidőben választ kapjon, a szükséges intézkedések megtörténjenek.</p>

                            <h4 className='mt-2'>11.1. Nemzeti Adatvédelmi és Információszabadság Hatóság</h4><br></br>
                            <p>Az Érintett a Nemzeti Adatvédelmi és Információszabadság Hatósághoz (a továbbiakban: Hatóság) fordulhat, ha véleménye szerint személyes adatainak kezelésével kapcsolatban jogsérelem érte, vagy annak közvetlen veszélye fennáll, kivéve, ha az adott ügyben bírósági eljárás van folyamatban. A Hatóság bejelentés alapján vizsgálatot folytat, illetőleg hatósági eljárást indít.<br></br><br></br>

                                A Nemzeti Adatvédelmi és Információszabadság Hatóság elérhetőségei:<br></br>
                                postacím:        1363, Budapest, Pf.:9.<br></br>
                                cím:                1055, Budapest, Falk Miksa utca 9-11.<br></br>
                                telefon:           +36 -1-391-1400<br></br>
                                fax:                 +36 -1-391-1410<br></br>
                                e-mail:            ugyfelszolgalat@naih.hu<br></br>
                                internetcím:    www.naih.hu</p>


                            <h3 className='mt-2'>XIII. Adatbiztonság</h3>
                            <p>Az adatbiztonság követelménynek eleget téve az adatkezelési műveleteket úgy kell megtervezni és végrehajtani, hogy az biztosítsa az érintettek magánszférájának védelmét. Szolgáltató gondoskodik az adatok biztonságáról, megteszi azokat a technikai és szervezési intézkedéseket, és kialakítja azokat az eljárási szabályokat, amelyek az adat- és titokvédelmi szabályok érvényre juttatásához szükségesek.<br></br>
                                Az adatokat megfelelő intézkedésekkel védi a jogosulatlan hozzáférés, megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés, valamint a véletlen megsemmisülés és sérülés, továbbá az alkalmazott technika megváltozásából fakadó hozzáférhetetlenné válás ellen.</p>
                            <p>Adatvédelmi incidens: a biztonság olyan sérülése, amely a továbbított, tárolt vagy más módon kezelt személyes adatok véletlen vagy jogellenes megsemmisítését, elvesztését, megváltoztatását, jogosulatlan közlését vagy az azokhoz való jogosulatlan hozzáférést eredményezi.<br></br>
                                Ha az adatvédelmi incidens valószínűsíthetően magas kockázattal jár a természetes személyek jogaira és szabadságaira nézve, az Adatkezelő indokolatlan késedelem nélkül, világosan és közérthetően tájékoztatja az érintettet az adatvédelmi incidensről.</p>

                            <h3 className='mt-2'>XIV. Az adatkezelési Szabályzat módosítása</h3>
                            <p>A Szolgáltató fenntartja magának a jogot, hogy a jelen Adatkezelési Szabályzatot bármikor módosítsa, amennyiben azt indokoltnak tartja, vagy a módosítást jogszabályváltozás szükségessé teszi. Amennyiben az Érintett a módosításokkal nem ért egyet, úgy a változásról történő tudomásszerzést követő 8 napon belül írásban jelezheti ezt Szolgáltató felé. Ebben az esetben az Érintett személyes adatait Szolgáltató köteles törölni, kivéve, ha az adatok törlését jogi kötelezettség teljesítése nem teszi lehetővé.<br></br>
                                Amennyiben az érintett a módosítást a tudomásszerzést követő fenti határidőn belül nem kifogásolja, úgy az új adatvédelmi szabályok hatályba lépnek az érintett személyes adatai tekintetében.</p>

                            <p className='mt-3'>Budapest, 2023. február 10.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}