import { HashLink as Link } from 'react-router-hash-link';

export default function Faq() {
    return (
        <>
            <section className="faqSection pt-10">
                <div className="container-fluid">
                    <div className="faqColumn">
                        <h2 className="fwb fs1rem">Gyakran felmerülő kérdések</h2>
                    </div>
                    <Link to="/gyakori-kerdesek#time" title="Mennyi idő alatt készül el az oldalam?">
                        <div className="faqColumn">
                            <h3>Mennyi idő alatt készül el az oldalam?</h3>
                            <i className="bi bi-arrow-right"></i>
                        </div>
                    </Link>
                    <Link to="/gyakori-kerdesek#price" title="Mennyibe fog kerülni a projektem kivitelezése?">
                        <div className="faqColumn">
                            <h3>Mennyibe fog kerülni a projektem kivitelezése?</h3>
                            <i className="bi bi-arrow-right"></i>
                        </div>
                    </Link>
                    <Link to="/gyakori-kerdesek#quotation" title="Hogyan néz ki a folyamat?">
                        <div className="faqColumn">
                            <h3>Hogyan néz ki a folyamat?</h3>
                            <i className="bi bi-arrow-right"></i>
                        </div>
                    </Link>
                    <Link to="/gyakori-kerdesek#upgrade" title="Meglévő projektek átvételét, részfeladatok kivitelezését is vállaljátok?">
                        <div className="faqColumn">
                            <h3>Meglévő projektek átvételét, részfeladatok kivitelezését is vállaljátok?</h3>
                            <i className="bi bi-arrow-right"></i>
                        </div>
                    </Link>
                    <Link to="/gyakori-kerdesek#support" title="Mire számíthatok, miután kész az oldalam?">
                        <div className="faqColumn">
                            <h3>Mire számíthatok, miután kész az oldalam?</h3>
                            <i className="bi bi-arrow-right"></i>
                        </div>
                    </Link>
                </div>
            </section>
        </>
    );
}