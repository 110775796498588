import { HashLink as Link } from 'react-router-hash-link';

export default function ReferenceItems() {
    return (
        <div className="referenceCalumn">
            <div className="referenceItem">
                <Link to="/referenciak#budapestbabszinhaz">
                    <img src="/images/references/nexodot_ref_budapestbabszinhaz.png" className="referenceImg" alt="" />
                    <div className="center referenceLink">
                        budapestbabszinhaz.hu
                    </div>
                </Link>
            </div>
            <div className="referenceItem">
                <Link target='_blank' to="https://pcland.hu">
                    <img src="/images/references/nexodot_ref_pcland.png" className="referenceImg" alt="" />
                    <div className="center referenceLink">
                        pcland.hu
                    </div>
                </Link>
            </div>
            <div className="referenceItem">
                <Link to="/referenciak#avantagemanagement">
                    <img src="/images/references/nexodot_ref_avantagemanagement.png" className="referenceImg" alt="" />
                    <div className="center referenceLink">
                        avantagemanagement.com
                    </div>
                </Link>
            </div>
            <div className="referenceItem">
                <Link target='_blank' to="https://karinthyszinhaz.hu">
                    <img src="/images/references/nexodot_ref_karinthy.png" className="referenceImg" alt="" />
                    <div className="center referenceLink">
                        karinthyszinhaz.hu
                    </div>
                </Link>
            </div>
            <div className="referenceItem">
                <Link to="/referenciak#factorysupport">
                    <img src="/images/references/nexodot_ref_factorysupport.png" className="referenceImg" alt="" />
                    <div className="center referenceLink">
                        factorysupport.hu
                    </div>
                </Link>
            </div>
            <div className="referenceItem">
                <Link to="/referenciak#orszagenergia">
                    <img src="/images/references/nexodot_ref_orszagenergia.png" className="referenceImg" alt="" />
                    <div className="center referenceLink">
                        orszagenergia.hu
                    </div>
                </Link>
            </div>
            <div className="referenceItem">
                <Link to="/referenciak#rebelplast">
                    <img src="/images/references/nexodot_ref_rebelplast.png" className="referenceImg" alt="" />
                    <div className="center referenceLink">
                        rebelplast.com
                    </div>
                </Link>
            </div>
            <div className="referenceItem">
                <Link to="/referenciak#bujtas-plomba">
                    <img src="/images/references/nexodot_ref_bujtasplomba.png" className="referenceImg" alt="" />
                    <div className="center referenceLink">
                        bujtas-plomba.hu
                    </div>
                </Link>
            </div>
        </div>
    );
}