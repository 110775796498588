import ReferenceItems from "../ReferenceItems";
import { HashLink as Link } from 'react-router-hash-link';

export default function References() {
    return (
        <>
            <section className="referenceSection pt-12" id="references">
                <div className="container-fluid referenceContainer">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="referenceHeader">
                                <h2>Referenciáink</h2>
                                <p>Büszkék vagyunk a munkáinkra, a jelenlegi és korábbi, elégedett ügyfeleinkre.</p>
                                <br></br>
                                <p>Gyarapítsd az elégedettek táborát, hagyd, hogy teljesen <b>célba találjuk az elképzeléseidet!</b></p>
                                <div className="center">
                                    <img src="/images/reference.jpg" className="img-fluid referenceHeaderImg" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="d-none d-lg-block">
                                <ReferenceItems />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="d-none d-lg-block">
                                <Link to="/referenciak" className="btn btn-link mt-5" title="Összes referencia">
                                    Összes referencia
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-block d-lg-none">
                    <ReferenceItems />
                    <Link to="/referenciak" className="btn btn-link mt-2" title="Összes referencia" style={{ marginLeft: "15px"}}>
                        Összes referencia
                    </Link>
                </div>
            </section>
        </>
    );
}