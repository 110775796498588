export default function About() {
    return (
        <>
            <div className="container-fluid pt-8" id="about">
                <span className="bgElement bgElement2"></span>
                <div className="row">
                    <div className="col-12">
                        <p>
                            A cégünk több mint 10 éves tapasztalattal nyújt informatikai megoldásokat kis és nagy vállalkozások számára. Fő
                            profilunk a weboldalak, webes rendszereket, asztali és mobilalkalmazások tervezése, fejlesztése és üzemeltetése.
                            Ügyfeleink között tudhatunk kisebb vállalkozásoktól kezdve nagymúltú, multinacionális ipari cégeket is.
                            Célunk, hogy az ügyfél igényeinek teljes mértékben megfelelve a lehető legmodernebb, legfelhasználóbarátabb és a
                            legegyszerűbben kezelhető rendszereket tervezzük és fejlesszük.
                            Teljeskörűen képesek vagyunk egykézből lefedni egy vállalkozás igényeit a webes rendszerek fejlesztésétől kezdve,
                            marketing anyagok elkészítésén át a termékfotózás, légifelvételek készítéséig.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}