import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { Helmet } from 'react-helmet'

export default function References() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Referenciák</title>
                <meta name="title" content="Referenciák" />
            </Helmet>
            <div className="">
                <div className="container-fluid mt-8">
                    <div className="pageHeader" style={{ minHeight: 'auto' }}>
                        <h2 className="title1">Referenciák</h2>
                    </div>
                </div>

                <div className="container-fluid referencesContainer">
                    <div className="row referenceBox" id="budapestbabszinhaz">
                        <div className="col-12 col-lg-7">
                            <div className="referenceImage">
                                <img src="/images/references/reference_page/nexodot_budapestbabszinhaz_ref.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 pt-2 pt-lg-3">
                            <Link to="https://budapestbabszinhaz.hu/" target="_blank" title="">
                                budapestbabszinhaz.hu
                            </Link>
                            <p className="title2">Budapest Bábszínház</p>
                            <p>A Budapest Bábszínháznak több, mint hetvenéves történelme van, több generáció nőtt fel az előadásain.<br></br><br></br> Voltak nagy korszakai, emblematikus előadásai, képes volt reagálni az őt körülvevő változásokra, kulturális behatásokra. Budapest egyetlen kőszínháza, melynek műfaji meghatározása: BÁBSZÍNHÁZ.</p>
                        </div>
                    </div>
                    <div className="row referenceBox" id="avantagemanagement">
                        <div className="col-12 col-lg-5 pt-2 pt-lg-3 order-2 order-lg-1">
                            <Link to="https://avantagemanagement.com/" target="_blank" title="">
                                avantagemanagement.com
                            </Link>
                            <p className="title2">Avantage Management</p>
                            <p>Avantage Management modellügynökség, amely Magyarország egyik legrangosabb ügynökségévé nőtte ki magát. Fő profiljuk, hogy megtalálják azokat a fiatalokat, akikből kiemelkedő nemzetközi modelleket nevelhetnek. Az elmúlt évtizedek tapasztalatai alapján hiszik, hogy sikerült álmainkat megvalósítanunk! Szolgáltatásaik magas színvonalának egyértelmű bizonyítéka partnereik számának folyamatos növekedése, illetve meglévő partnereik újabb és újabb felkérései.</p>
                        </div>
                        <div className="col-12 col-lg-7 order-1 order-lg-2">
                            <div className="referenceImage">
                                <img src="/images/references/reference_page/nexodot_avantagemanagement_ref.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row referenceBox" id="factorysupport">
                        <div className="col-12 col-lg-7">
                            <div className="referenceImage">
                                <img src="/images/references/reference_page/nexodot_factorysupport_ref.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 pt-2 pt-lg-3">
                            <Link to="https://factorysupport.hu/index.php" target="_blank" title="">
                                factorysupport.hu
                            </Link>
                            <p className="title2">Factory Support</p>
                            <p>Cégük alapvető célja az ipar területén működő gyártóüzemek részére kimagasló színvonalú műszaki és technikai támogatás nyújtása.</p>
                        </div>
                    </div>

                    <div className="row referenceBox" id="orszagenergia">
                        <div className="col-12 col-lg-5 pt-2 pt-lg-3 order-2 order-lg-1">
                            <Link to="https://orszagenergia.hu/index.php" target="_blank" title="">
                                orszagenergia.hu
                            </Link>
                            <p className="title2">Ország Energia</p>
                            <p>Otthoni és ipari villanyszerelés. Magánszemélyek és vállalkozások számára egyaránt kiviteleznek családi- és társasházakat. <br></br><br></br>A családi házak építése során felmerülő egyedi igényeket a lehetőségeik szerint minél pontosabban és teljesebben igyekeznek kiszolgálni. Az ehhez szükséges szakmai támogatást pedig mindig ők biztosítják.</p>
                        </div>
                        <div className="col-12 col-lg-7 order-1 order-lg-2">
                            <div className="referenceImage">
                                <img src="/images/references/reference_page/nexodot_orszagenergia_ref.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="row referenceBox" id="rebelplast">
                        <div className="col-12 col-lg-7">
                            <div className="referenceImage">
                                <img src="/images/references/reference_page/nexodot_rebelplast_ref.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 pt-2 pt-lg-3">
                            <Link to="https://rebelplast.com/" target="_blank" title="">
                                rebelplast.com
                            </Link>
                            <p className="title2">Rebelplast</p>
                            <p>A RebelPlast kompozit alapanyagokból készülő termékek tervezéssel és kivitelezéssel foglalkozik, de minden alapanyaggal szívesen foglalkoznak, melyre gépparkuk is lehetőséget biztosít számukra.<br></br><br></br> Igyekeznek minden megrendelői igényt kielégíteni, egészen a tervezéstől a kivitelezés utolsó pillanatáig.</p>
                        </div>
                    </div>

                    <div className="row referenceBox" id="bujtas-plomba">
                        <div className="col-12 col-lg-5 pt-2 pt-lg-3 order-2 order-lg-1">
                            <Link to="https://bujtas-plomba.hu/" target="_blank" title="">
                                bujtas-plomba.hu
                            </Link>
                            <p className="title2">Bujtás Plomba</p>
                            <p>A cég, mint családi vállalkozás az 1970-es évektől gyárt plomba fogót. Elsőként a honvédség számára készültek, később más vállalatok pl.: a magyar posta is partnerük lett.<br></br><br></br> A cég tevékenysége a plombák és a hozzá kapcsolódó kiegészítők gyártása kis és nagy szériában, egyedi megoldások kivitelezésével. Egyedi plomba vésetek pl. logók gyártásával.</p>
                        </div>
                        <div className="col-12 col-lg-7 order-1 order-lg-2">
                            <div className="referenceImage">
                                <img src="/images/references/reference_page/nexodot_bujtasplomba_ref.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}