export default function Process() {
    return (
        <>
            <div className="container-fluid processContainer mt-5 mt-lg-8">
                <div className="row justify-content-center align-items-center pt-lg-6">
                    <div className="col-12 col-lg-4 processImgBox order-2 order-lg-1">
                        <img src="/images/process.png" className="img-fluid" alt="" />
                    </div>
                    <div className="col-12 col-lg-8 order-1 order-lg-2 pb-lg-6">
                        <div className="row">
                            <div className="col-12 mb-4 mb-lg-3">
                                <h2>Hogy néz ki a folyamat?</h2>
                                <p>Egy gondolattal kezdődik, de reméljük egy mosollyal végződik.</p>
                            </div>
                            <div className="col-12 col-lg-6 mb-4 mb-lg-3">
                                <div className="processBox">
                                    <img src="/images/01.svg" className="bgElement01" alt="" />
                                    <p className="fw7 mb-lg-1">Van egy jó ötleted?</p>
                                    <p>Keress fel minket elérhetőségink egyikén és egy konzultáció keretein belül átbeszéljük, hogy mi az elképzelésed, a kitűzött célod.</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mb-4 mb-lg-3">
                                <div className="processBox">
                                    <img src="/images/02.svg" className="bgElement02" alt="" />
                                    <p className="fw7 mb-lg-1">Tervezzük meg együtt!</p>
                                    <p>A megbeszéltek alapján megtervezzük az általad elképzelt weboldalt, alkalmazást. A kész terveket elküldjük számodra ellenőrzésre.</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                <div className="processBox">
                                    <img src="/images/03.svg" className="bgElement03" alt="" />
                                    <p className="fw7 mb-lg-1">Megvalósítás, fejlesztés...</p>
                                    <p>A tervek alapján elkezdjük a projekt megvalósítását. A fejlesztés során folyamatosan tájékoztatunk téged a fejlesztés haladásáról.</p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 mb-lg-0">
                                <div className="processBox">
                                    <img src="/images/04.svg" className="bgElement04" alt="" />
                                    <p className="fw7 mb-lg-1">Lezárás és támogatás</p>
                                    <p>Átadás után sem engedjük el a kezed. Amennyiben ígény van rá, teljeskörű támogatást biztosíthatunk a weboldalad zökkenőmentes működése érdekében.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}