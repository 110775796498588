import { Link } from "react-router-dom";

export default function Ready() {
    return (
        <>
            <section className="readySection">
                <div className="container-fluid mt-5 mt-lg-8">
                    <div className="ready">
                        <p className="title1">Készen állsz a közös munkára?</p>
                        <div className="readyBottom">
                            <Link to="/kapcsolat#form"
                                className="btn btn-primary mt-lg-3 d-none d-lg-block"
                                title="Ajánlatkérés"
                            >
                                Ajánlatkérés
                            </Link>
                            <p className="mt-05 mt-lg-0">
                                Amennyiben szívesen dolgoznál velünk együtt, keress fel minket elérhetőségeink egyikén.
                                Esetleg még bizonytalan vagy?
                                <br></br>
                                Nem akadály, szívesen segítünk és válaszolunk minden kérdésedre.
                            </p>
                            <div className="d-block d-lg-none">
                                <Link to="/kapcsolat#form" className="btn btn-primary mt-8" title="Ajánlatkérés">
                                    Ajánlatkérés
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}