import { HashLink as Link } from 'react-router-hash-link';

export default function Navbar() {
    return (
        <nav className="navbar navbar-light fixed-top navbar-expand-lg">
            <div className="container-fluid">

                <div className="navbar-brand">
                    <Link to="/" title="Kezdőlap">
                        <img src="/images/nexodot-cr-b.svg" className="img-fluid" alt="" />
                    </Link>
                </div>

                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <i className="bi bi-pause"></i>
                </button>

                <div className="collapse navbar-collapse justify-content-stretch" id="navbarNavDropdown">
                    <ul className="navbar-nav mx-auto">
                        <li className="nav-item">
                            <Link to="/#szolgaltatasok" className="nav-link" title="Szolgáltatások">
                                <span>Szolgáltatások</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/referenciak" className="nav-link" title="Referenciák">
                                <span>Referenciák</span>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link to="/kapcsolat" className="nav-link" title="Kapcsolat">
                                <span>Kapcsolat</span>
                            </Link>
                        </li>
                        <li className="nav-item navbarButton">
                            <Link to="/kapcsolat#form" className="btn btn-outline-primary" title="Ajánlatkérés">
                                <span>Ajánlatkérés</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}