import { HashLink as Link } from 'react-router-hash-link';
import { Helmet } from "react-helmet";

export default function PageNotFound() {

    return (
        <>
            <Helmet>
                <title>Hiba</title>
                <meta name="title" content="Hiba" />
            </Helmet>
            <div className="mt-5" id="page404">
                <div className="container-fluid pt-3 pb-3 pt-lg-5 pb-lg-5">
                    <div className="mt-5 row justify-content-center">
                        <div className="col-12 col-lg-4 center">
                            404 - Az oldal nem található
                        </div>
                    </div>
                    <div className="mt-5 row justify-content-center">
                        <div className="col-12 col-lg-4 center">
                            <Link to="/">
                                Tovább a kezdőlapra
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}