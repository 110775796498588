import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery';

export default function Services() {
    return (
        <>
            <div className="container-fluid serviceContainer mt-4 mt-lg-10" id="szolgaltatasok">
                <div className="serviceHeader mb-3 mb-lg-5">
                    <div>
                        <h2>Szolgáltatásaink</h2>
                        <p>Szemezgess kedvedre szolgáltatásaink közül és dolgozzunk mi is együtt.</p>
                    </div>
                </div>
                <div className="serviceColumn">
                    <div className="serviceBox actived">
                        <div title="Weboldal fejlesztés">
                            <i className="bi bi-braces-asterisk textColorPrimary3"></i>
                            <span className="primaryColor3"></span>
                            <p>Weboldal fejlesztés</p>
                            <p>Vállalati rendszerek</p>
                            <p>Mobil alkalmazásfejlesztés</p>
                            <p>Webáruház fejlesztés</p>
                        </div>
                    </div>
                    <div className="serviceBox">
                        <div title="Grafikai tervezés">
                            <i className="bi bi-vector-pen textColorPrimary2"></i>
                            <span className="primaryColor2"></span>
                            <p>Webdesign</p>
                            <p>Logó tervezés</p>
                            <p>Arculat tervezés</p>
                            <p>Marketing anyagok</p>
                        </div>
                    </div>
                    <div className="serviceBox">
                        <div title="Tartalomgyártás">
                            <i className="bi bi-camera2 textColorPrimary4"></i>
                            <span className="primaryColor4"></span>
                            <p>Fotózás</p>
                            <p>Videókészítés</p>
                            <p>Légifelvétel</p>
                            <p>Hőkamerás felvétel</p>
                        </div>
                    </div>
                    <i className="serviceBoxChoose"></i>
                </div>
                <Link to="/kapcsolat#form" className="btn btn-primary mt-3 mt-lg-4" title="Ajánlatkérés">
                    Ajánlatkérés
                </Link>
            </div>
        </>
    );
}

$(document).ready(function () {
    $(function () {
        const w = $(window).width()
        if (w > 768) {
            $('.serviceBox').hover(function () {
                event($(this))
            })
        } else {
            $('.serviceBox').on('click', function () {
                event($(this))
            })
        }
        function event($this) {
            const idx = $this.index()
            $this.addClass('actived').siblings().removeClass('actived')
            $('.serviceBox').eq(idx).addClass('actived').siblings().removeClass('actived')
            $('.serviceBoxChoose').css('transform', `translate3d(${100 * idx}%,0,0)`)
        }
    })
});