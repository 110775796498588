import { HashLink as Link } from 'react-router-hash-link';

export default function Header() {
    return (
        <>
            <section className="bannerSection">
                <div className="d-block d-lg-none">
                    <div className="bannerText2">
                        <h3 className="textColorPrimary3">Weboldal készítés</h3>
                        <h3 className="textColorPrimary2">Grafikai tervezés</h3>
                        <h3 className="textColorPrimary4">Tartalomgyártás</h3>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="bannerContainer">
                        <div className="bannerHeader">
                            <div className="d-none d-lg-block">
                                <div className="d-flex">
                                    <h3 className="textColorPrimary3">Weboldal készítés,&nbsp;</h3>
                                    <h3 className="textColorPrimary2">Grafikai tervezés,&nbsp;</h3>
                                    <h3 className="textColorPrimary4">Tartalomgyártás</h3>
                                </div>
                            </div>
                            <p className="title1">Egy helyen <br></br> <span>minden!?</span></p>
                        </div>
                        <div className="bannerImg">
                            <img src="/images/banner.png" className="img-fluid" alt="" />
                        </div>
                        <div className="bannerBottom">
                            <Link to="/kapcsolat#form" className="btn btn-primary" title="Ajánlatkérés">
                                Ajánlatkérés
                            </Link>
                            <p>Mi segítünk kivitelezni projekted az elejétől a végéig.</p>
                            <i className="bi bi-arrow-down"></i>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}