import { useEffect } from 'react';
import { Helmet } from 'react-helmet'

export default function Impress() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Impresszum</title>
                <meta name="title" content="Impresszum" />
            </Helmet>
            <div className="">
                <div className="container-fluid mt-8">
                    <div className="pageHeader" style={{ minHeight: "auto" }}>
                        <h2 className="title1">Impresszum</h2>
                    </div>
                </div>

                <div className="container-fluid pt-lg-6 impress">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6">
                            <h3 className="mb-2">Nexodot Informatikai és Szolgáltató Kft.</h3>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="p-1">Székhely, számlázási cím:</td>
                                        <td>1162 Budapest, János utca 26.</td>
                                    </tr>
                                    <tr>
                                        <td>Iroda cím:</td>
                                        <td>1139 Budapest, Forgách utca 20.</td>
                                    </tr>
                                    <tr>
                                        <td>Cégjegyzékszám:</td>
                                        <td>01-09-674216</td>
                                    </tr>
                                    <tr>
                                        <td>Adószám:</td>
                                        <td>12404140-2-42</td>
                                    </tr>
                                    <tr>
                                        <td>Közösségi adószám:</td>
                                        <td>HU12404140</td>
                                    </tr>
                                    <tr>
                                        <td>Céget nyilvántartó cégbíróság:</td>
                                        <td>Fővárosi Törvényszék Cégbírósága</td>
                                    </tr>
                                    <tr>
                                        <td>Statisztikai számjele:</td>
                                        <td>12404140-6201-113-01</td>
                                    </tr>
                                    <tr>
                                        <td>Számlaszám (forint):</td>
                                        <td>10404072-40717298-00000000</td>
                                    </tr>
                                    <tr>
                                        <td>Számlaszám (euró):</td>
                                        <td>HU21104040654949565552561004</td>
                                    </tr>
                                    <tr>
                                        <td>BIC/SWIFT:</td>
                                        <td>OKHBHUHB</td>
                                    </tr>
                                    <tr>
                                        <td>Ügyvezető:</td>
                                        <td>Horváth Zsolt</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}