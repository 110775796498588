import Header from '../components/home/Header'
import Services from '../components/home/Services'
import Packages from '../components/home/Packages'
import References from '../components/home/References'
import About from '../components/home/About'
import Process from '../components/home/Process'
import Faq from '../components/home/Faq'
import Ready from '../components/home/Ready'
import { useEffect } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export default function Home() {
  const { pathname, hash, key } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, hash, key])

  return (
    <>
      <Helmet>
        <title>Kezdőlap</title>
        <meta name="title" content="Kezdőlap" />
      </Helmet>
      <Header />
      <MobileView>
        <Services />
        <References />
      </MobileView>
      <About />
      <Process />
      <BrowserView>
        <Services />
        <References />
      </BrowserView>
      <Faq />
      <Ready />
    </>
  )
}