import { HashLink as Link } from 'react-router-hash-link';
import $ from 'jquery';

export default function Services() {
    return (
        <>
            <div className="container-fluid packagesContainer mt-4 mt-lg-10" id="csomagok">
                <div className="packagesHeader mb-3 mb-lg-5">
                    <div>
                        <h2>Csomagajánlataink</h2>
                        <p>
                            Egyszerű de nagyszerű weboldalt szeretnél <b>költséghatékony áron</b>?
                            <br/>
                            Válassz <b>WordPress</b> csomagunk egyikéből melyeket <b>kezdő vállalkozások számára</b> hoztunk létre.
                            <br/>
                            <br/>
                            <b>Komolyabb szintre lépnél</b> és szeretnél egy egyedi fejlesztésű, teljes mértékben testre szabható,
                            <br/>
                            biztonságos, időtállóbb weboldalt? <b>Válaszd egyedi</b> weboldal <b>fejlesztésünket</b>.
                        </p>
                    </div>
                </div>
                <div className="packagesColumn">
                    <div className="row">
                        <div className="col-12 col-lg-3">
                            <div className="packagesBox">
                                <p className="packageName">Basic</p>
                                <p className="packageDesc">"Legyen egy weboldalam ahol megtalálnak és felvehetik velem a kapcsolatot.”</p>
                                <p className="packagePrice">159.990 Ft-tól</p>
                                <p className="packageSmallDesc">Áraink iránymutató jellegűek</p>
                                <Link to="/kapcsolat#form" className="btn btn-outline-primary" title="Ajánlatkérés">
                                    Ajánlatkérés
                                </Link>
                                <ul className="packageChecks">
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span><b>WordPress alapú weboldal</b></span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Modern, felhasználóbarát dizájn kialakítás</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Reszponzív, mobilbarát weboldal</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Maximum 3 aloldal</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Alapszintű SEO beállítás</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Google Analytics és Search console beállítás</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>GDPR technikai megfelelés</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Kapcsolatfelvételi űrlap</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>1 hónap technikai támogatás</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="packagesBox active">
                                <p className="packageName">Intermediate</p>
                                <p className="packageDesc">“Vannak elképzeléseim és szeretnék valami hasonlót, mint...”</p>
                                <span className="packagePopular">Legnépszerűbb</span>
                                <p className="packagePrice">259.990 Ft-tól</p>
                                <p className="packageSmallDesc">Áraink iránymutató jellegűek</p>
                                <Link to="/kapcsolat#form" className="btn btn-primary" title="Ajánlatkérés">
                                    Ajánlatkérés
                                </Link>
                                <ul className="packageChecks">
                                    <li>
                                        <span><b>Minden, ami a “Basic” csomagban és...</b></span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Maximum 7 aloldal</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Blog, hírek, cikkek</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Képgaléria</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Hírlevél feliratkozás</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Több nyelvű oldal</span>
                                    </li>
                                    <li>
                                        <span><b>Ajándék</b></span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Domain és tárhely 1 évre</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>2 hónap technikai támogatás</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="packagesBox">
                                <p className="packageName">Advanced</p>
                                <p className="packageDesc">“Szeretném, ha a  weboldalam kitűnne a versenytársaimétól!”</p>
                                <p className="packagePrice">369.990 Ft-tól</p>
                                <p className="packageSmallDesc">Áraink iránymutató jellegűek</p>
                                <Link to="/kapcsolat#form" className="btn btn-outline-primary" title="Ajánlatkérés">
                                    Ajánlatkérés
                                </Link>
                                <ul className="packageChecks">
                                    <li>
                                        <span><b>Minden, ami a “Intermediate” csomagban és...</b></span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Maximum 12 aloldal</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Webáruház modul</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Számlázó integráció</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Gyakran Ismételt Kérdések</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Marketing eszközök beüzemelése</span>
                                    </li>
                                    <li>
                                        <span><b>Ajándék</b></span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>4 hónap technikai támogatás</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Logótervezés</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="packagesBox individual">
                                <p className="packageName">Individual</p>
                                <p className="packageDesc">“Egyedi fejlesztésű, teljes mértékben testre szabható, biztonságos, időtállóbb weboldalt szeretnék.”</p>
                                <p className="packagePrice">Egyedi árazás</p>
                                <p className="packageSmallDesc">Dizájntól és a funkcióktól függően tudunk árajánlatot adni.</p>
                                <Link to="/kapcsolat#form" className="btn btn-primary" title="Ajánlatkérés">
                                    Ajánlatkérés
                                </Link>
                                <ul className="packageChecks">
                                    <li className="checkPrimary">
                                        <i className="bi bi-check"></i>
                                        <span><b>Egyedi fejlesztés</b></span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Korlátlan aloldal</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Teljes mértékben testre szabható</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Kiemelt szintű biztonság</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Szupergyors</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Időtállóbb rendszer</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Korlátlan bővíthetőség</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Egyedi admin oldal</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>6 hónap technikai támogatás</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 mt-2 mb-2">
                            <div className="center my-1">
                                <p className="mb-0">Opcionálisan választható grafikai munkák kedvezményesen a fejlesztési csomagok mellé.</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="packagesBox">
                                <p className="packageNameSmall">Basic graphics</p>
                                <p className="packagePrice">69.990 Ft-tól</p>
                                <p className="packageSmallDesc mb-3">Áraink iránymutató jellegűek</p>
                                <ul className="packageChecks">
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Logotípia (betűlogó)</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Névjegykártya (1 oldalas)</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="packagesBox">
                                <p className="packageNameSmall">Intermediate graphics</p>
                                <p className="packagePrice">99.990 Ft-tól</p>
                                <p className="packageSmallDesc mb-3">Áraink iránymutató jellegűek</p>
                                <ul className="packageChecks">
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Monogram logó</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Névjegykártya (1 oldalas)</span>
                                    </li>
                                    <li>
                                        <span><b>Ajándék</b></span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Social média profil képek a logóval</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="packagesBox active">
                                <p className="packageNameSmall">Advanced graphics</p>
                                <p className="packagePrice">149.990 Ft-tól</p>
                                <p className="packageSmallDesc mb-3">Áraink iránymutató jellegűek</p>
                                <ul className="packageChecks">
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Kombinált logó (név + szimbólum)</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Névjegykártya (2 oldalas)</span>
                                    </li>
                                    <li>
                                        <span><b>Ajándék</b></span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>Social média profil képek a logóval + borítókép</span>
                                    </li>
                                    <li>
                                        <i className="bi bi-check"></i>
                                        <span>1 db kreatív social média poszt</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="packagesBox individual">
                                <p className="packageSmallDesc">Individual graphics</p>
                                <p className="packagePrice">Egyedi csomag</p>
                                <Link to="/kapcsolat#form" className="btn btn-primary" title="Ajánlatkérés">
                                    Ajánlatkérés
                                </Link>
                                <p className="packageSmallDesc">Ha egyedi grafikai csomagra vagy más kreatív anyagokra van szükséged, vedd fel velünk a kapcsolatot és mi elkészítjük neked!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

$(document).ready(function () {

});