import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { Helmet } from "react-helmet";

export default function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Kapcsolat</title>
                <meta name="title" content="Kapcsolat" />
            </Helmet>
            <div className="">
                <div className="container-fluid mt-8">
                    <div className="pageHeader">
                        <h2 className="title1">Ajánlatkérés</h2>
                        <div className="pageHeaderBottom">
                            <i className="bi bi-arrow-down"></i>
                            <p>Amennyiben szívesen dolgoznál velünk együtt, keress fel minket elérhetőségeink egyikén. Esetleg még bizonytalan vagy?
                                Nem akadály, szívesen segítünk és válaszolunk minden kérdésedre.</p>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-2 mt-lg-4 contactPageContainer">
                    <div className="row">
                        <div className="col-12 col-lg-9">
                            <div className="contactPageInner">
                                <div className="row">
                                    <div className="col-12 col-lg-4">
                                        <Link rel="noreferrer" to="tel://+36205114770">
                                            <div className="contactMeBox">
                                                <div>
                                                    <p><i className="bi bi-phone"></i>Telefon</p>
                                                    <p className="title6">+36 20 511 4770</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                        <Link rel="noreferrer" to="mailto:info@nexodot.hu">
                                            <div className="contactMeBox">
                                                <i className="bi bi-mailbox"></i>
                                                <div>
                                                    <p>E-mail cím</p>
                                                    <p className="title6">info@nexodot.hu</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                                        <Link rel="noreferrer" to="https://goo.gl/maps/EKeGENxmeF8JVUSP9" target="_blank">
                                            <div className="contactMeBox">
                                                <i className="bi bi-geo"></i>
                                                <div>
                                                    <p className="contactMeMaps">Iroda</p>
                                                    <p className="title6">1139 Budapest, <br></br> Forgách utca 20.</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-lg-4 mt-3 mt-lg-5">
                                        <Link rel="noreferrer" to="https://www.instagram.com/nexodot/" target="_blank">
                                            <div className="contactMeBox">
                                                <i className="bi bi-instagram"></i>
                                                <div>
                                                    <p>Instagram</p>
                                                    <p className="title6">#nexodot</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-lg-4 mt-3 mt-lg-5">
                                        <Link rel="noreferrer" to="https://www.facebook.com/nexodot" target="_blank">
                                            <div className="contactMeBox">
                                                <i className="bi bi-facebook"></i>
                                                <div>
                                                    <p>Facebook</p>
                                                    <p className="title6">@Nexodot</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-lg-4 mt-3 mt-lg-5">
                                        <Link rel="noreferrer" to="https://www.linkedin.com/company/nexodot-kft/" target="_blank">
                                            <div className="contactMeBox">
                                                <i className="bi bi-linkedin"></i>
                                                <div>
                                                    <p>LinkedIn</p>
                                                    <p className="title6">Nexodot</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 d-none d-lg-block">
                            <img src="/images/contact.png" className="img-fluid" alt="" />
                        </div>
                    </div>
                </div>

                <div className="container-fluid" id="form">
                    <div className="contactForm mt-2 mt-lg-8">
                        <div className="row">
                            <div className="col-12 col-lg-5">
                                <i className="bi bi-mailbox"></i>
                                <h1>Mi szívesen segítünk, írj nekünk!</h1>
                            </div>
                            <div className="col-12 col-lg-7 mt-3">
                                <div className="row g-2">
                                    <div className="col-12 col-lg-6">
                                        <div className="form-floating">
                                            <input type="text"
                                                id="_contact_name"
                                                className="form-control"
                                                placeholder="Név"
                                                required
                                            />
                                            <label htmlFor="_contact_name">
                                                Név
                                                <span style={{ color: "red" }}>*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="form-floating">
                                            <input type="text"
                                                id="_contact_company_name"
                                                className="form-control"
                                                placeholder="Cégnév"
                                            />
                                            <label htmlFor="_contact_company_name">
                                                Cégnév
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="form-floating">
                                            <input type="text"
                                                id="_contact_email"
                                                className="form-control"
                                                placeholder="E-mail cím"
                                                required
                                            />
                                            <label htmlFor="_contact_email">
                                                E-mail cím
                                                <span style={{ color: "red" }}>*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                        <div className="form-floating">
                                            <input type="text"
                                                id="_contact_phone"
                                                className="form-control"
                                                placeholder="Telefonszám"
                                                required
                                            />
                                            <label htmlFor="_contact_phone">
                                                Telefonszám
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <div className="form-floating">
                                            <select id="_contact_service" className="form-control" placeholder="Választott szolgáltatás">
                                                <option value={0}>Válassz szolgáltatást</option>
                                                <option value={"information"}>Csak érdeklődöm</option>
                                                <option value={"basic_web"}>Basic weboldalkészítés</option>
                                                <option value={"intermediate_web"}>Intermediate weboldalkészítés</option>
                                                <option value={"advanced_web"}>Advanced weboldalkészítés</option>
                                                <option value={"individual_web"}>Egyedi weboldalkészítés</option>
                                                <option value={"basic_graphic"}>Basic grafikai csomag</option>
                                                <option value={"intermediate_graphic"}>Intermediate grafikai csomag</option>
                                                <option value={"advanced_graphic"}>Advanced grafikai csomag</option>
                                                <option value={"individual_graphic"}>Egyedi grafikai csomag</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-floating textareaInput">
                                            <textarea itemType="text"
                                                id="_contact_description"
                                                className="form-control"
                                                placeholder="Miben segíthetünk?"
                                                name="message"
                                                rows={5}
                                                required
                                            ></textarea>
                                            <label htmlFor="_contact_description">
                                                Miben segíthetünk?
                                                <span style={{ color: 'red' }}>*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div style={{ fontSize: "13px", marginTop: "0" }}>
                                        This site is protected by reCAPTCHA and the Google&nbsp;
                                        <Link ref={element => {
                                            if (element) element.style.setProperty('font-size', '13px', 'important');
                                        }} to="https://policies.google.com/privacy" title="Privacy Policy">Privacy Policy</Link> and &nbsp;
                                        <Link ref={element => {
                                            if (element) element.style.setProperty('font-size', '13px', 'important');
                                        }} to="https://policies.google.com/terms" target="_blank" title="Terms of Service">Terms of Service</Link> apply.
                                    </div>
                                    <div className="col-md-12 pt-1">
                                        <div className="confirmPrivacy">
                                            <input type="checkbox" id="contact-cbx" class="cbx" name="_remember_me" style={{ display: "none" }} />
                                            <label htmlFor="contact-cbx" className="check">
                                                <svg width="18px" height="18px" viewBox="0 0 18 18">
                                                    <path
                                                        d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                                    <polyline points="1 9 7 14 15 4"></polyline>
                                                </svg>
                                                <span>
                                                    <small>
                                                        Hozzájárulok az általam megadott adataim kapcsolatfelvétel céljából történő kezeléséhez.&nbsp;
                                                        <u>
                                                            <Link to="/adatkezelesi-tajekoztato" target="_blank" rel="noreferrer" className="hasLink" title="Adatkezelési tájékoztató">
                                                                Adatkezelési tájékoztató
                                                            </Link>
                                                        </u>
                                                    </small>
                                                </span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 mt-3">
                                        <button className="btn btn-primary btn-send-contact" title="Küldés">
                                            Küldés &nbsp;<i className="fas fa-arrow-right fa-fw"></i>
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}