import { HashLink as Link } from 'react-router-hash-link';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet'

export default function Faq() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet>
                <title>Gyakori kérdések</title>
                <meta name="title" content="Gyakori kérdések" />
            </Helmet>
            <div className="">
                <div className="container-fluid mt-8">
                    <div className="pageHeader">
                        <h2 className="title1">Gyakran felmerülő kérdések</h2>
                        <div className="pageHeaderBottom">
                            <i className="bi bi-arrow-down"></i>
                            <p>
                                Itt találod az általános alapvető kérdéseidre a választ, olvasd át figyelmesen és ha még marad benned kérdés szívesen válaszolunk.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="container-fluid pt-6 faqPageContainer">
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="faqControlColumn">
                                <div className="faqControlBox">
                                    <Link to="#time">Elkészülési idő</Link>
                                    <Link to="#price">Mennyibe kerül?</Link>
                                    <Link to="#quotation">Árajánlat kérése</Link>
                                    <Link to="#invoice">Mikor kell fizetnem?</Link>
                                    <Link to="#upgrade">Meglévő weboldal fejlesztése</Link>
                                    <Link to="#support">Támogatás, utánkövetés</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8">
                            <div className="faqBox" id="time">
                                <div className="faqBoxHeader">
                                    <div>
                                        <p className="textColor2 mb-05">Gyakori kérdések</p>
                                        <h2 className="textColorPrimary2"><i className="bi bi-hourglass-split"></i>&nbsp; Elkészülési idő</h2>
                                    </div>
                                </div>
                                <div className="faqBoxBody">
                                    <p className="mt-1">
                                        Minden projekt más és más bonyolultságú, így nem lehet pontos elkészülési időt írni a részletek ismerete nélkül.
                                        Minden fejlesztésnél az ígények és az elképzelések megbeszélése után tudjuk pontosan megadni a fejlesztéshez szükséges időt.<br></br><br></br>

                                        Általánosságban elmondható, hogy egy egyszerű bemutatkozó oldal akár 2 héten belül is elkészülhet. Azonban egy nagy és bonyolult weboldal elkészítése akár hónapokat is igénybe vehet. A részletek megbeszélése után pontosan megtudjuk adni, mennyi idő szükséges a weboldalad, vagy az alkalmazásod elkészítéséhez.
                                    </p>
                                </div>
                            </div>
                            <div className="faqBox" id="price">
                                <div className="faqBoxHeader">
                                    <div>
                                        <p className="textColor2 mb-05">Gyakori kérdések</p>
                                        <h2 className="textColorPrimary3"><i className="bi bi-coin"></i>&nbsp; Mennyibe kerül?</h2>
                                    </div>
                                </div>
                                <div className="faqBoxBody">
                                    <p className="mt-1">
                                        Egy weboldal vagy alkalmazás ára nagy mértékben függ a megvalósítandó funkcióktól és a designtól. Minél bonyolultabb egy-egy funkció vagy design elem, annál több időt vesz ígénybe a fejlesztése.<br></br><br></br>

                                        Minden projektnél törekszünk arra, hogy a lehető legrövidebb időt vegye igénybe a fejlesztés. Igyekszünk ügyfeleink elképzelésire a lehetőségekhez mérten olyan javaslatokat tenni, amelyek egyszerűsítik és gyorsítják a kívánt funkció fejlesztését. Mindezt természetesen úgy, hogy az nem megy a minőség rovására.<br></br><br></br>

                                        Egy egyszerű bemutatkozó oldal annak minden funkciójával és egy nagyszerű designal már akár pár százezer forintból is elkészíthető. Azonban egy nagy, sok bonyolult funkcióval rendelkező weboldal vagy alkalmazás akár milliós nagyságrendű is lehet.<br></br><br></br>

                                        Minden weboldal és alkalmazás egyedi, így a részletek ismerete nélkül nem lehet pontos árat megadni. <Link to="/kapcsolat#form"><strong>Vegye fel velünk a kapcsolatot</strong></Link> és igyekszünk önnek a lehető legjobb és legköltséghatékonyabb weboldalt vagy alkalmazást elkészíteni.
                                    </p>
                                </div>
                            </div>
                            <div className="faqBox" id="quotation">
                                <div className="faqBoxHeader">
                                    <div>
                                        <p className="textColor2 mb-05">Gyakori kérdések</p>
                                        <h2 className="textColorPrimary4"><i className="bi bi-file-earmark-text"></i>&nbsp; Árajánlat kérése</h2>
                                    </div>
                                </div>
                                <div className="faqBoxBody">
                                    <p className="mt-1">
                                        Amennyiben kész elképzeléssel rendelkezel, <Link to="/kapcsolat#form"><strong>lépj velünk a kapcsolatba</strong></Link> elérhetőségeink egyikén. Egy személyes, vagy akár online találkozó keretein belül átbeszéljük, hogy milyen weboldalt vagy alkalmazást szeretnél, milyen designal és funkciókkal és akár 1 napon belül kézhez is kaphatod a részletes ajánlatunkat.<br></br><br></br>

                                        Azonban, ha nem tudod pontosan mit szeretnél, természetesen ebben is tudunk segíteni. Egy találkozó alkalmával megbeszéljük, hogy mi pontosan a célod a weboldallal vagy az alkalmazással. Sorra vesszük, hogy milyen folyamatokat szeretnél lefedni, automatizálni és hogy milyen funkciókat készítsünk el neked a projekt során. A megbeszélés során igyekszünk megismerni a jelenlegi működésed, a munkafolyamatokat és akár olyan új ötleteket, funkciókat javasolni, amire lehet elsőre nem is gondoltál. <Link to="/kapcsolat#form"><strong>Keress fel minket</strong></Link> és vágjunk bele a közös munkába!
                                    </p>
                                </div>
                            </div>
                            <div className="faqBox" id="invoice">
                                <div className="faqBoxHeader">
                                    <div>
                                        <p className="textColor2 mb-05">Gyakori kérdések</p>
                                        <h2 className="textColorPrimary3"><i className="bi bi-node-plus"></i>&nbsp; Mikor kell fizetnem?</h2>
                                    </div>
                                </div>
                                <div className="faqBoxBody">
                                    <p className="mt-1">
                                        Minden projektnél egyedi fizetési konstrukciót alakítunk ki. Igyekszünk úgy elkészíteni, hogy számodra is megfelelő és tervezhető legyen. Kisebb projekteknél a legtöbb ügyfelünk általában a fejlesztési díj 50%-t választja előlegként, majd a fennmaradó összeg 1 vagy akár több részletben kerül kifizetésre.<br></br><br></br>

                                        Nagyobb projekteknél lehetőség van részletfizetésre. A fejlesztés haladása során, megállapodás szerint a részletek folyamatosan kerülnek kiegyenlítésre. Így akár egy nagyobb összegű projekt költségeit is igazítani tudod a bevételeidhez, kiadásaidhoz.
                                    </p>
                                </div>
                            </div>
                            <div className="faqBox" id="upgrade">
                                <div className="faqBoxHeader">
                                    <div>
                                        <p className="textColor2 mb-05">Gyakori kérdések</p>
                                        <h2 className="textColorPrimary2"><i className="bi bi-sliders2"></i>&nbsp; Meglévő weboldal fejlesztése</h2>
                                    </div>
                                </div>
                                <div className="faqBoxBody">
                                    <p className="mt-1">
                                        Ha a meglévő weboldaladat szeretnéd továbbfejleszteni, több szempontból szükséges megvizsgálnunk az oldalad. Sajnos sok esetben nem tudjuk vállalni a továbbfejlesztést, mivel az esetek nagy részében több időt vesz igénybe a jelenlegi oldal fejlesztése, mint egy új oldal elkészítése az alapoktól.<br></br><br></br>

                                        Minden esetben alaposan megvizsgáljuk és elemezzük az oldalad, majd részletesen vázoljuk számodra a lehetőségeket.
                                    </p>
                                </div>
                            </div>
                            <div className="faqBox" id="support">
                                <div className="faqBoxHeader">
                                    <div>
                                        <p className="textColor2 mb-05">Gyakori kérdések</p>
                                        <h2 className="textColorPrimary4"><i className="bi bi-person-check"></i>&nbsp; Támogatás, utánkövetés</h2>
                                    </div>
                                </div>
                                <div className="faqBoxBody">
                                    <p className="mt-1">
                                        Miután elkészült az általad elképzelt projekt, továbbra sem engedjük el a kezed! A garancia lejártáig a fejlesztésből adódó hiábat térítésmentesen javítjuk.
                                        A projekt méretétől függően további fejlesztési órákat biztosítunk számodra térítésmentesen apróbb módosításokhoz. Ezeket feltudod használni arra az esetekre, ha a projekt átadása után valamivel kapcsolatban mégis meggondolnád magad. Ezeket térítésmentesen módosítjuk neked a megadott keret erejéig.<br></br><br></br>

                                        A továbbiakban két lehetőséget biztosítunk számodra. Lehetőség van támogatási szerződés kötésére, amelynél előre meghatározott fejlesztési órát biztosítunk kedvezményes áron. A szerződésben meghatározott havi óraszám erejéig bármikor fordulhatsz hozzánk kéréseddel és mi prioritásként kezeljük azt. Amennyiben a fejlesztés túllépi a megadott keretet, bizonyos óraszámig továbbra is kedvezményesen veheted igénybe szolgáltatásainkat. Nagyobb volumenű fejlesztésekhez azonban nem vehető igénybe a kedvezményes óradíj. Ezek az esetek külön megállapodás részét képezik.<br></br><br></br>

                                        Természetesen támogatási szerződés nélkül is bármikor fordulhatsz hozzánk kéréseddel. Ilyenkor teljes óradíjjal számolunk és kapacitásunktól, terheltségünktől függően a megadott határidőre készítjük el a fejlesztést.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}