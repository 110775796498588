import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Faq from "../pages/Faq";
import References from "../pages/References";
import Contact from "../pages/Contact";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Impress from "../pages/Impress";
import PageNotFound from "./PageNotFound";
import AfterContact from "./AfterContact";
import AfterSubscribe from "./AfterSubscribe";

export default function Routing() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/gyakori-kerdesek" element={<Faq />} />
      <Route path="/referenciak" element={<References />} />
      <Route path="/kapcsolat" element={<Contact />} />
      <Route path="/adatkezelesi-tajekoztato" element={<PrivacyPolicy />} />
      <Route path="/impresszum" element={<Impress />} />
      <Route path="/koszonjuk" element={<AfterContact />} />
      <Route path="/koszonjuk_feliratkozas" element={<AfterSubscribe />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}