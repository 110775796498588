import { HashLink as Link } from 'react-router-hash-link';

export default function Footer() {
    return (
        <footer className="mt-5" id="kapcsolat">
            <div className="container-fluid pt-3 pb-3 pt-lg-5 pb-lg-5">
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="footerLogo pb-3">
                            <Link to="/" title="Kezdőlap">
                                <img src="/images/nexodot-cr-b.svg" alt="" />
                            </Link>
                        </div>
                        <p className="pb-1">
                            Amennyiben valamelyik szolálgtatásunk elnyerte a tetszésedet és szeretnél velünk dolgozni, keress fel minket elérhetőségeink egyikén.
                            <br></br><br></br>
                            Mi már örömmel várjuk veled is a közös munkát!
                        </p>
                        <div className="list-group">
                            <div className="list-group-item">
                                <Link to="tel://+36205114770">
                                    +36 20 511 4770
                                </Link>
                            </div>
                            <div className="list-group-item">
                                <Link to="mailto:info@nexodot.hu">
                                    info@nexodot.hu
                                </Link>
                            </div>
                            <div className="list-group-item">
                                <Link to="https://goo.gl/maps/EKeGENxmeF8JVUSP9">
                                    1139 Budapest, Forgách utca 20.
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-2"></div>
                    <div className="col-12 col-lg-6">
                        <div className="newsletter">
                            <p className="pb-lg-3">Iratkozz fel hírlevelünkre, hogy értesülhess újdonságainkról!</p>
                            <div className="form-floating">
                                <input type="email"
                                    id="_subscribe_email"
                                    className="form-control"
                                    placeholder="E-mail cím" />
                                <label htmlFor="_subscribe_email">
                                    E-mail cím
                                    <span style={{ color: "red" }}>*</span>
                                </label>
                                <button className="btn btn-newsSend subscribe-btn"><i className="bi bi-arrow-right"></i></button>
                            </div>
                            <div className="mb-1" style={{ fontSize: "13px" }}>
                                This site is protected by reCAPTCHA and the Google&nbsp;
                                <Link ref={element => {
                                    if (element) element.style.setProperty('font-size', '13px', 'important');
                                }} to="https://policies.google.com/privacy" title="Privacy Policy">Privacy Policy</Link> and &nbsp;
                                <Link ref={element => {
                                    if (element) element.style.setProperty('font-size', '13px', 'important');
                                }} to="https://policies.google.com/terms" target="_blank" title="Terms of Service">Terms of Service</Link> apply.
                            </div>
                            <div className="confirmPrivacy">
                                <input type="checkbox" id="subscribe-cbx" className="cbx" name="_remember_me" style={{ display: "none" }} />
                                <label htmlFor="subscribe-cbx" className="check">
                                    <svg width="18px" height="18px" viewBox="0 0 18 18">
                                        <path
                                            d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z"></path>
                                        <polyline points="1 9 7 14 15 4"></polyline>
                                    </svg>
                                    <span>
                                        <small>
                                            Hozzájárulok az általam megadott adataim kapcsolatfelvétel céljából történő kezeléséhez.&nbsp;
                                            <u>
                                                <Link to="/adatkezelesi-tajekoztato" target="_blank" rel="noreferrer" className="hasLink" title="Adatkezelési tájékoztató">
                                                    Adatkezelési tájékoztató
                                                </Link>
                                            </u>
                                        </small>
                                    </span>
                                </label>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 col-lg-4">
                                <div className="list-group">
                                    <div className="list-group-item">
                                        <Link to="https://www.facebook.com/nexodot" target="_blank" rel="noreferrer">
                                            Facebook
                                        </Link>
                                    </div>
                                    <div className="list-group-item">
                                        <Link to="https://www.instagram.com/nexodot/" target="_blank" rel="noreferrer">
                                            Instagram
                                        </Link>
                                    </div>
                                    <div className="list-group-item">
                                        <Link to="https://www.linkedin.com/company/nexodot-kft/" target="_blank" rel="noreferrer">
                                            LinkedIn
                                        </Link>
                                    </div>
                                    <div className="list-group-item">
                                        <Link to="/kapcsolat" title="Kapcsolat">
                                            Kapcsolat
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="list-group">
                                    <div className="list-group-item">
                                        <Link to="/" title="Kezdőlap">
                                            Kezdőlap
                                        </Link>
                                    </div>
                                    <div className="list-group-item">
                                        <Link to="/#services" title="Szolgáltatások">
                                            Szolgáltatások
                                        </Link>
                                    </div>
                                    <div className="list-group-item">
                                        <Link to="/referenciak" title="Referenciák">
                                            Referenciák
                                        </Link>
                                    </div>
                                    <div className="list-group-item">
                                        <Link to="/gyakori-kerdesek" title="Gyakori kérdések">
                                            Gyakori kérdések
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="list-group">
                                    <div className="list-group-item">
                                        <Link to="/adatkezelesi-tajekoztato" title="Adatkezelési tájékoztató">
                                            Adatkezelési tájékoztató
                                        </Link>
                                    </div>
                                    <div className="list-group-item">
                                        <button id="cookie-settings" title="Süti beállítások">
                                            Süti beállítások
                                        </button>
                                    </div>
                                    <div className="list-group-item">
                                        <Link to="/impresszum" title="Impresszum">
                                            Impresszum
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <p className="copyright">
                            {new Date().getFullYear()}&nbsp;
                            &copy;
                            Nexodot Kft. | Minden jog fenntartva!
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}